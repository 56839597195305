import { Component, OnDestroy, OnInit, VERSION, Version, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SessionObject, UrlConstants, Utils } from './acore/utility';
import { SMSService } from './pages/desktop/SMS/sms.service';
import { BaseComponent } from './acore/base';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserDetails } from './pages/login/core-login/user-details.model';
import { catchError, finalize, tap } from 'rxjs/operators';
import {throwError as observableThrowError, Observable } from 'rxjs';


declare var Highcharts;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None 
})
export class AppComponent implements OnInit, OnDestroy {

  userDetails: UserDetails;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    // console.log(this.router.config);
    // this.addCKEditor();
    // this.renderDownload();
    this.userDetails = SessionObject.getUserDetails();
    // if(this.userDetails){
    //   this.checkApiTokenValidity();
    // }
  }
  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    // this.authSignOut();
  }


  checkApiTokenValidity() {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        }
      )
    };
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userDetails.Token);

    this.http.post(UrlConstants.STATES, "", httpOptions).pipe(
      catchError(this.onCatch),
      tap((res: Response) => {
          // console.log("RESPONSE",res);
        this.onSuccess(res);

      }, (error: any) => {
        // console.log('error');
        this.onError(error);
      }),
      finalize(() => {
        // console.log('final.......................................................');
        this.onEnd();
      }));
  }

  private onCatch(error: any, caught: Observable<any>): Observable<any> {
    // console.log('Catch, error: ', error);
    return observableThrowError(error);
  }

  private onSuccess(res: Response): void {
      //  console.log('Request successful');
  }

  private onError(res: Response): void {
    this.authSignOut();
    // console.log('Error, status code: ' + res.status);
  }

  private onEnd(): void {
  }

  authSignOut() {
    localStorage.removeItem('UserDetails');
    localStorage.removeItem('_AuthToken');
    localStorage.removeItem('sessionObject');
    localStorage.clear();
    this.router.navigate(['']);
    // $.window.location.reload(true);
  }


  addCKEditor() {
    const url = './assets/js/ckeditor/ckeditor.js';
    Utils.loadJS(url);

  }

  renderDownload() {
    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
      var path = [



        // Arrow stem
        'M', x + w * 0.8, y,
        'L', x + w * 0.8, y,

        // Arrow head
        'M', x + w * 0.4, y,
        'L', x + w * 0.4, y,
        // Box
        'M', x, y,
        'L', x, y,
        'L', x, y,
        'L', x, y
      ];
      return path;
    };
  }

}
